<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            จัดการเว็บไซต์ ({{ count ? count : 0 }})
          </h1>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white">
        <b-row class="section no-gutters px-3 px-sm-0">
          <b-col>
            <div class="table">
              <div class="header">
                <div
                  class="detail d-flex justify-content-center align-items-center"
                >
                  <p>ลำดับ</p>
                  <p>ชื่อฟิลด์</p>
                  <p>สถานะการแสดงผล</p>
                </div>
              </div>
              <div class="body">
                <draggable
                  v-model="items"
                  group="people"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <div
                    class="detail position-relative d-flex justify-content-center"
                    v-for="(i, index) of items"
                  >
                    <p>{{ index + 1 }}</p>
                    <p>{{ i.key }}</p>

                    <div class="toggle">
                      <b-form-checkbox
                        switch
                        class="radio-active"
                        size="lg"
                        v-model="i.enabled"
                        @change="handldStatus(i)"
                      >
                      </b-form-checkbox>
                      <span :class="i.enabled ? 'text-success' : 'text-danger'">
                        {{ i.enabled ? "แสดงผล" : "ไม่แสดงผล" }}
                      </span>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1 p-3">
          <b-col class="text-sm-right">
            <button
              type="button"
              @click="submit()"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Modal -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Draggable from "vuedraggable";

export default {
  name: "Section",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    Draggable,
  },
  data() {
    return {
      fields: [
        {
          key: "ids",
          label: "",
        },
        {
          key: "sortOrder",
          label: "ลำดับ",
          class: "text-nowrap d-flex justify-content-center align-items-center",
        },
        {
          key: "key",
          label: "ชื่อฟิลด์",
          class: "w-100px text-nowrap",
        },
        {
          key: "enable",
          label: "สถานะการแสดงผล",
          class: "w-100px text-nowrap",
        },
      ],
      items: [],
      count: "",
      isBusy: false,
      isDisable: false,
      modalMessage: "",
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/SectionHomePage/list`,
        null,
        this.$headers
      );

      if (data.result == 1) {
        this.items = data.detail.dataList;
        this.count = data.detail.count;
        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    async submit() {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      this.items.map((e, index) => {
        e.sortOrder = index + 1;
        return e;
      });

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/SectionHomePage/list/update`,
        null,
        this.$headers,
        {
          list: this.items,
        }
      );

      this.isDisable = false;
      this.$refs.modalLoading.hide();
      this.modalMessage = data.detail;

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    handldStatus(data) {
      let index = this.items.findIndex(function(e) {
        return e.key === data.key;
      });
      this.items[index].enabled = !this.items[index].enabled;
    },
  },
};
</script>

<style scoped>
.table .header {
  background: #d2d2d2;
}

.table .header .detail {
  gap: 6rem;
}

.table .header .detail p {
  width: 100%;
  color: #4f5d73;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-weight: bold;
}

.table .body {
  padding: 0.5rem 0rem;
}

.table .body .detail {
  margin: 1rem 0rem;
  gap: 8rem;
  border-top: 1px solid #d2d2d2;
  margin: 0rem 1rem;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  height: 3rem;
  border-bottom: none;
}

.table .body .detail:hover {
  background: #f4f4f4;
}

.table .body .detail:first-child {
  border-top: none;
}

.table .body .detail p,
.table .body .detail .toggle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.table .body .detail .toggle {
  gap: 1rem;
}

.table .body .detail .custom-switch {
  padding-bottom: 0.5rem;
}

.table .body .detail .toggle span {
  width: 5rem;
}

.btn {
  cursor: pointer;
  font-size: 1rem;
}

.select-custom {
  width: 5rem !important;
}

@media (max-width: 601px) {
  .table .header .detail p {
    font-size: 1rem;
  }

  .section {
    padding: 0 !important;
  }

  .table .header .detail {
    gap: 2rem;
  }

  .table .body .detail {
    gap: 3rem;
  }
}

@media (max-width: 390px) {
  .table .body .detail {
    gap: 2rem;
  }
}
</style>
